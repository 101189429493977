.root {
  display: flex;
  flex-direction: column;

  .infoContainer {
    column-gap: 8px;
    display: flex;
    justify-content: space-between;
  }

  .infoTimeLeft {
    align-items: center;
    display: flex;
    column-gap: 9px;
  }

  .progressBarContainer {
    border-radius: 20px;
    height: 8px;
    overflow: hidden;
    width: 100%;
  }

  .progressBarFiller {
    height: inherit;
    transition: width 2s ease-in-out;
    overflow: hidden;
  }

  .progressBarFillerBackground {
    height: inherit;
    transition: width 2s ease-in-out;
  }
}