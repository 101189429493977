@import 'theme/variables.scss';

.root {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.steps {
  margin-bottom: 32px;
}

.field {
  margin-bottom: 0;
}

.stepsContent {
  margin-bottom: 24px;
}

.stepsActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rightButton {
  margin-left: auto;
}
