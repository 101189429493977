.root {
  padding: 16px 0;

  .listWrap {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .date {
      margin-bottom: 12px;
      display: block;
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }

    .statusWrap {
      display: flex;
      flex-direction: column;
    }

    .statusInner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      .statusText {
        display: block;
        margin: 12px 0;
      }
    }
  }
}
