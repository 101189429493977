@import 'theme/variables.scss';

:root {
  --rgb-accent: 22, 150, 156;
}

.emojiPopover {
  & :global {
    .ant-popover-inner-content {
      padding: 0 !important;
      border-radius: 8px;
    }

    em-emoji-picker {
      &[data-theme='light'] {
        --em-rgb-accent: var(--rgb-accent, 34, 102, 237) !important;
      }
    }
  }
}

.emojiIcon {
  cursor: pointer;
}

.toolbarBtn {
  height: auto;
  padding: 0;
  border: 0;
  display: flex;
  line-height: 0;
}

.toolbarSubmit {
  padding: 0;
  height: auto;
  border: 0;
}

.btnEvent {
  width: 16px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnSubmitComment {
  font-weight: bold;
  padding: 0;
  margin-left: auto;
  margin-right: 6px;

  [disabled] {
    padding: 0;
  }
}

.private {
  font-size: 12px;
}

.isPrivate {
  margin-right: 0;
}
