@import 'theme/variables.scss';

.form {
  display: flex;
  flex-direction: column;

  .fromItem {
    margin-bottom: 24px !important;
  }

  .radioGruop {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: $screen-sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    .radio {
      display: flex;
      align-items: center;

      & > span {
        top: 0;
      }

      &:first-child {
        @media (max-width: $screen-sm) {
          margin-bottom: 16px;
        }
      }

      & > span:last-child {
        display: flex;
        align-items: center;
      }
    }
  }

  .title {
    text-align: center;
    font-weight: 700;
    margin-bottom: 24px;

    @media (max-width: $screen-sm) {
      font-size: 16px;
    }
  }

  .error {
    display: flex;
    align-items: flex-start;

    .iconError {
      color: $color-red-70;
      font-size: 18px;
      margin-right: 8px;
    }

    .errorText {
      font-size: 12px;
      line-height: 16px;
      display: inline-block;
      margin-bottom: 10px;
      color: $text-color;
    }
  }

  .btnWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .btnSubmit {
    padding: 0 35px;
    margin: 0 auto;
  }

  .messages {
    margin-top: 16px;

    .message {
      font-size: 12px;
      align-items: center;
      display: flex;
      justify-content: center;
      border-radius: $border-radius-base;
      padding: 8px 32px;
      text-align: center;

      &.messageGreen {
        color: $color-green-10;
        background-color: $color-green-30;
      }

      &.messageRed {
        color: $color-red-35;
        background-color: $color-red-90;
      }
    }
  }

  .btnSkip {
    margin: 0 auto;
  }

  .checkbox {
    display: flex;

    :global(.ant-checkbox) {
      margin-top: 4px;
    }
  }

  .item {
    width: 100%;
  }

  .column {
    flex-direction: column;
    display: flex;
  }

  @media (max-width: $screen-sm) {
    .inputGroup {
      flex-direction: column;
    }

    .countrySelect {
      margin-bottom: 15px;
      border-radius: 4px !important;
      border: 1px solid $color-black-65 !important;
    }

    .companyWrap {
      width: 100% !important;
    }

    .companySelect {
      border-radius: 4px !important;
      border: 1px solid $color-black-65 !important;
    }
  }

  &.isCreateWorkspace {
    .radioGruop {
      justify-content: flex-start;
    }
  }
}

.flexWrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  .item {
    margin-bottom: 6px;
  }
}

.flexForm {
  height: 100%;
  display: flex;
  flex-direction: column;

  .btnWrap {
    margin-top: auto;
  }

  .btnSubmit {
    margin: 0 0 0 auto;
  }
}

.btnsSubmitContainer {
  display: flex;
  flex-direction: column;
  margin-top: auto;

  .btnsSubmit {
    order: 2;
    display: flex;
    flex-direction: row-reverse;
    margin-left: auto;

    & .btnSkip {
      align-self: center;
      margin-right: 30px;
    }
  }

  .messages {
    order: 1;
    margin: 16px 0;
  }
}

.select {
  width: 100%;
}

.unverifiedWorkspaceWrap {
  display: flex;
  margin-bottom: 24px;

  .country {
    height: 100%;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
    max-width: 150px;
  }

  .titleCompany {
    margin-bottom: 0;
    width: 100%;

    & > input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
