@import 'theme/variables.scss';

.root {
  display: flex;
  align-items: center;

  .icon {
    cursor: pointer;
    margin-left: 4px;
  }
}

.menu {
  background-color: $color-white;
  box-shadow: $shadow-3;
  border-radius: 4px;
  padding: 16px;
  max-height: 200px;
  max-width: 300px;
  overflow: auto;

  div {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.item {
  display: flex;
  flex-wrap: nowrap;
  max-width: 95%;
  align-items: center;
}

.link {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.skeletonTitle {
  margin: 4px 0 !important;
}

.noAccessEntityType {
  color: $color-black-65 !important;
}

.noAccess {
  color: $color-black-65;
}
