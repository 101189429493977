.root {
  margin-bottom: 20px;
}

.dateWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
}

.taskCreatedAt {
  display: flex;
  align-items: center;
  gap: 8px;
}

.notificationSent {
  display: inline-block;
  max-width: 280px;
}

.notificationSentIcon {
  display: inline-block;
  margin-left: 8px;
}
