.root {
  :root & :global(.ant-form-explain) {
    top: 156px;
  }

  .description {
    margin-bottom: 24px;
  }
}

.priceWrap {
  grid-gap: 16px;
  gap: 16px;
  display: grid;
  grid-template-columns: 1fr 100px;
}
