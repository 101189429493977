@import 'theme/variables.scss';

.root {
  padding: 24px;

  .description {
    margin-bottom: 24px;
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  .radioButtons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .button {
    margin-top: 8px;
    align-self: flex-end;
    font-size: 16px;
  }

  .fileButton {
    border: 1px solid $color-brand !important;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
