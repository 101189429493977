@import 'theme/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  width: 100%;

  &.isLog {
    padding: 4px;
    border-radius: 4px;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }

  &.firstGroupMessage,
  &.groupMessage,
  &.lastGroupMessage {
    padding: 8px 8px 16px;
    background-color: $color-black-95;
  }

  &.firstGroupMessage {
    border-radius: 8px 8px 0 0;
  }

  &.lastGroupMessage {
    padding-bottom: 8px;
    border-radius: 0 0 8px 8px;
    margin-bottom: 16px;
  }

  &.firstGroupMessage.lastGroupMessage {
    border-radius: 8px;
  }

  &.source {
    padding: 8px;
    border-radius: 4px;
    background-color: $color-white;

    .contentWrap {
      background-color: transparent !important;
      padding: 0 !important;
      border: none !important;
      max-width: unset !important;
    }

    .message {
      display: grid;
      grid-template-columns: 1fr auto;

      .messageActions {
        opacity: 1;
        pointer-events: all;
        height: 34px;
      }
    }
  }

  .message {
    display: flex;
    gap: 8px;
    width: 100%;

    .visible {
      opacity: 1;
      pointer-events: all;
    }

    &:hover {
      .messageActions {
        opacity: 1;
        pointer-events: all;
      }
    }

    &.my {
      flex-direction: row-reverse;
    }

    .channelIcon > svg {
      border-radius: 50%;
    }

    .contentWrap {
      border-radius: 0px 8px 8px 8px;
      min-width: 200px;
      max-width: 80%;

      &.my {
        border-radius: 8px 0px 8px 8px;
        background-color: $color-brand-90;
      }

      &.online {
        background-color: $color-black-95;
      }

      &.comment {
        background-color: $color-white;
        border: 1px solid #bdbfc1;
      }

      &.isLog {
        background-color: $color-white;
        display: flex;
        padding: 0;
        gap: 8px;
        width: 100%;
        max-width: 100%;
        align-items: baseline;
        border-radius: 0;
      }
    }

    &.isLog {
      flex-direction: row;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &.isLogWithHistory {
      gap: 8px;
      flex-direction: row;
    }

    .contentText {
      display: flex;
      gap: 8px;
      align-items: center;

      &.isLog {
        color: $color-black-45;
        font-size: 12px;
      }
    }

    .subject {
      margin-bottom: 4px;
    }

    .openEmail {
      padding: 0;
      height: auto;
      border: 0;
      font-weight: 400;
      align-self: flex-start;
    }

    &.isLog {
      margin-bottom: 0;
    }

    .historyOpenBtn {
      width: fit-content;
      height: auto;
      padding: 0;
      font-size: 12px;
      border: none;
      line-height: normal;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 8px;

    .headerLeft {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 4px;
      text-transform: capitalize;
    }

    .fullname {
      word-break: break-word;
    }

    .username {
      text-transform: none;
    }

    &.isLog {
      position: absolute;
      top: 1px;
      background-color: $color-white;
      margin-bottom: 0;
    }
  }

  .groupInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
  }

  .chatActions {
    margin-left: 20px;
    display: flex;
    gap: 16px;

    .chatActionBtn {
      height: auto;
      padding: 0;
      border: 0;
    }
  }
}

.logType {
  cursor: pointer;
}

.leadInfoTitle {
  display: block;
  margin-bottom: 2px;
}

.leadInfoDescription {
  margin-bottom: 8px;
}

.leadSupportInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.labels {
  margin: 0;

  .leadLabel {
    display: block;
    margin: 0;
  }
}

.referrer {
  margin-bottom: 0 !important;
}

.recipients {
  margin-bottom: 8px;

  .recipientsHeader {
    display: flex;
    align-items: center;
    gap: 8px;

    .recipientsBtn {
      padding: 0;
      color: $color-black-55 !important;
      height: auto;
    }
  }

  .recipientsTitle {
    margin: 0;
  }

  .recipientsList {
    margin: 0;
  }
}

.emoji {
  margin-top: 8px;
}
