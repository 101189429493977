@import 'theme/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
  padding: 8px;

  .attachments {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;

    .file:last-of-type {
      margin-bottom: 0;
    }
  }

  .attachmentWrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .fileSizeExceeded {
    gap: 0;
  }
}

.highlight {
  margin-top: 16px;
  background-color: $color-black-95;
}
