@import 'theme/variables.scss';

.ant-table {
  border: 1px solid $border-color-base;
  border-radius: $border-radius-base;
  font-size: 12px;

  .ant-table-header {
    overflow: hidden;
  }

  .ant-table-cell {
    &:before {
      display: none;
    }
  }

  .ant-table-thead > tr > th {
    background-color: $color-white;
  }

  &.ant-table-middle
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-thead > tr > th {
    padding: 8px 10px 8px;

    & .ant-table-cell-fix-right-first {
      right: 0;
    }
  }

  .ant-table-thead > tr > th .ant-table-column-sorters {
    display: inline-flex;
  }

  .ant-table-thead > tr > th .ant-table-header-column {
    vertical-align: initial;
  }

  .ant-table-thead > tr > th .ant-table-column-sorter {
    font-size: 11px;
    display: block;
  }

  .ant-table-tbody {
    vertical-align: top;
  }

  .ant-table-tbody > tr:not(.ant-table-measure-row) > td {
    padding: 8px 10px !important;
  }
  .ant-table-row-selected {
    &:hover {
      & > td {
        background: $color-black-98;
      }
    }

    & > td {
      color: inherit;
      background: $color-black-98;
    }
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }

  .ant-table-selection-column {
    vertical-align: middle;
  }

  .ant-table-column-title,
  .ant-table-cell {
    font-size: 12px;
    color: $color-black;
  }

  .ant-table-thead {
    .ant-table-cell {
      font-weight: 600;
    }
  }

  .ant-table-column-sort .ant-table-column-title {
    color: $color-brand;
  }

  .on {
    color: $color-brand;
  }

  .ant-table-placeholder {
    border-bottom: none;
  }
}
