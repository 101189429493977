.form {
  display: flex;
  gap: 12px;
}

.submitBtn {
  margin-top: 20px;
  font-size: 16px;
  display: block;
}

.numberInput {
  :global {
    .ant-input-group-addon {
      min-width: 65px;
    }
  }
}
