@import 'theme/variables.scss';

.alertMessage {
  color: $color-black;
  font-weight: 600;
  font-size: 14px;
}

.alertDescription {
  display: inline-block;
  color: $color-black;
  margin-bottom: 8px;
}

.commentWrap {
  display: flex;
  flex-direction: column;
}

.attachmentsWrap {
  padding: 0;
}

.alertWrap {
  :global {
    .ant-alert {
      padding: 8px 16px;
    }

    .ant-alert-message {
      font-size: 14px;
      line-height: 1;
      margin-bottom: 8px;
    }

    .ant-alert-description {
      font-size: 14px;
      line-height: 1;
    }
  }
}
