.icon {
  display: inline-block;
}

.isLeftMargin {
  margin-left: 4px;
}

.isRightMargin {
  margin-right: 8px;
}
